exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bodypainting-js": () => import("./../../../src/pages/bodypainting.js" /* webpackChunkName: "component---src-pages-bodypainting-js" */),
  "component---src-pages-grafika-cyfrowa-js": () => import("./../../../src/pages/grafika-cyfrowa.js" /* webpackChunkName: "component---src-pages-grafika-cyfrowa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lekcje-rysunku-i-malarstwa-js": () => import("./../../../src/pages/lekcje-rysunku-i-malarstwa.js" /* webpackChunkName: "component---src-pages-lekcje-rysunku-i-malarstwa-js" */),
  "component---src-pages-prace-tworcze-js": () => import("./../../../src/pages/prace-tworcze.js" /* webpackChunkName: "component---src-pages-prace-tworcze-js" */),
  "component---src-pages-renowacje-js": () => import("./../../../src/pages/renowacje.js" /* webpackChunkName: "component---src-pages-renowacje-js" */)
}

